.top_select[data-v-6886b42a] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iconfont[data-v-6886b42a] {
  font-size: 13px;
  margin-right: 4px;
}
.font-14[data-v-6886b42a] {
  font-size: 14px;
}
.item_icon[data-v-6886b42a] {
  cursor: pointer;
}
.each_border[data-v-6886b42a] {
  padding-right: 5px;
  border-right: 1px solid #b1adad;
  margin-right: 5px;
}
.each_border[data-v-6886b42a]:last-child {
  border-right: none;
}
.zj_name[data-v-6886b42a] {
  border-bottom: 1px solid #777474;
}